import { Tooltip as BsTooltip } from 'bootstrap'
import React, { useEffect, useRef } from 'react'

interface TooltipProps {
    text: string
    placement: string
    children: React.ReactElement
}

export const Tooltip = ({ text, placement, children }: TooltipProps) => {
  const childRef = useRef(undefined)

  useEffect(() => {
    // @ts-ignore TOFIX
    const t = new BsTooltip(childRef.current, {
      title: text,
      placement,
      trigger: 'hover',
    })
    return () => t.dispose()
  }, [placement, text])

  return React.cloneElement(children, { ref: childRef })
}
