/* eslint-disable max-len */
import moment from 'moment'

enum Technologies {
  // frontend
  React = 'React',
  Redux = 'Redux',
  TypeScript = 'TypeScript',
  Angular = 'Angular',
  Ionic = 'Ionic',
  Sass = 'Sass',
  jQuery = 'jQuery',

  // backend
  Python = 'Python',
  Django = 'Django',
  Express = 'Express',
  PHP = 'PHP',
  Laravel = 'Laravel',
  CSharp = 'C#',
  Linq = 'Linq',

  // database
  PostgreSQL = 'PostgreSQL',
  Redis = 'Redis',
  InfluxDB = 'InfluxDB',
  mySQL = 'mySQL',
  MSSQL = 'MSSQL',

  // devops
  Docker = 'Docker',
  Portainer = 'Portainer',
  GitHub = 'GitHub',
  GitLab = 'GitLab',
  Subversion = 'Subversion',
  AWS = 'AWS',
}

const useData = () => {
  const data = {
    firstName: 'Francesco',
    lastName: 'Meli',
    title: 'Full Stack Engineer',
    intro: 'Obsessed Web Developer.\nBrainstorms on how to model and store the world inside a database.\nLooking for a full-time position as software engineer yielding the opportunity to exploit the set of software development skills acquired with pounds of patience.',
    webIntro: 'Passionate Web Developer with over a decade of experience in frontend and backend development, skilled in React, Django, PostgreSQL, and Docker. I excel in delivering scalable web applications, from developing IoT data loggers to leading frontend development. As a Senior Fullstack Engineer, I created innovative solutions for a popular app, ensuring efficient deployment with React, TypeScript, CI/CD pipelines, and Docker. Additionally, I developed a seamless frontend for an insurance purchase section, collaborating on intuitive API design. \n\n I am now seeking freelance projects where I can leverage my diverse skills and passion for web development. With a strong foundation in both frontend and backend development and a commitment to continuous learning, I am excited to bring my expertise to new and challenging opportunities.',
    info: [
      { key: 'city', value: 'Reggio Emilia', tag: 'contacts' },
      { key: 'country', value: 'Italy', tag: 'contacts' },
      { key: 'birthdate', value: '17-09-1989', tag: 'contacts' },
      { key: 'phone', value: '+39 340 238 0765', tag: 'contacts' },
      { key: 'e-mail', value: 'iamfrancescomeli@gmail.com', tag: 'contacts' },
      { key: 'www', value: 'https://francescomeli.com', tag: 'contacts' },
      { key: 'linkedIn', value: 'https://www.linkedin.com/in/fmeli/', tag: 'socials', icon: 'linkedin' },
      { key: 'stackOverflow', value: 'https://stackoverflow.com/users/7754093/francesco-meli', icon: 'stack-overflow', tag: 'socials' },
      { key: 'github', value: 'https://github.com/pinkynrg', icon: 'github', tag: 'socials' },
    ],
    reviews: [
      {
        name: 'Rick Faucher',
        position: 'Chief Partnerships Officer',
        src: 'images/rick.jpeg',
        desc: "Francesco was exceptional in tackling a big project in a small amount of time for us. He was efficient and very knowledgeable in connecting a new website front-end through middleware to the backend API engine. His troubleshooting and QA skills were put to task and he rose to the challenge with ease. I wouldn't hesitate to add him to another project or a dev team in the future.",
        rating: 5,
      },
      {
        name: 'Ian Baker',
        position: 'Fullstack Software Engineer',
        src: 'images/ian.jpeg',
        desc: 'I can enthusiastically recommend Francesco as a Software Engineer! I had the pleasure of collaborating with him at The Connected Pet Company, where his expertise with front end technologies like React proved invaluable, allowing us to iterate quickly and meet a tight deadline. Francesco consistently delivers high-quality solutions and demonstrates a strong ability to juggle business, product, and technological challenges. His professionalism and dedication make him a valuable asset to any team.',
        rating: 5,
      },
      {
        name: 'Stefano Bertelli',
        position: 'Engineering Manager',
        src: 'images/stefano.jpeg',
        desc: "Francesco is a very talented engineer, always eager to challenge himself with new tasks. His analytical approach is remarkable. When dealing with new problems he has an open minded approach and it's not afraid of exploring new technologies to find leading edge solutions. He is a passionate developer with great communication skills and impressive dedication!",
        rating: 5,
      },
      {
        name: 'Tom Kotsakos',
        position: 'Business Analyst',
        src: 'images/unknown.png',
        desc: 'Francesco worked tirelessly on developing the back-end and front-end for our company website from scratch. He has a very analytical mind, learns quickly and thoroughly, and has a broad knowledge on tangential fields to our business which offered a perspective that helped us give input on the design.',
        rating: 5,
      },
    ],
    services: [
      {
        name: 'Frontend Development',
        desc: 'Developed and maintained web applications using ReactJS, Angular, and TypeScript. Ensured cross-browser compatibility and applied best practices in HTML, CSS, and JavaScript.',
        icon: 'fas fa-code',
      },
      {
        name: 'Full Stack Development',
        desc: 'Designed and developed applications with React Native, Ionic, Node.js, and Express. Implemented CI/CD pipelines for streamlined development and high-quality releases.',
        icon: 'fas fa-laptop-code',
      },
      {
        name: 'UI/UX Design',
        desc: 'Created user-centric designs, conducted usability testing, and developed wireframes and user flows to enhance the overall user experience.',
        icon: 'fas fa-pencil-ruler',
      },
      {
        name: 'Application Architecture',
        desc: 'Architected scalable web applications with Django and Node.js. Developed RESTful APIs and leveraged Docker for efficient deployment.',
        icon: 'fas fa-project-diagram',
      },
      {
        name: 'DevOps & CI/CD',
        desc: 'Automated deployment processes using GitHub Actions and Gitlab Pipelines. Dockerized applications to ensure consistency across environments.',
        icon: 'fas fa-tools',
      },
      {
        name: 'Database Management',
        desc: 'Managed and optimized PostgreSQL, InfluxDB, and Redis databases. Designed data models for efficient storage and retrieval.',
        icon: 'fas fa-database',
      },
      {
        name: 'API Development & Integration',
        desc: 'Developed and maintained RESTful APIs, ensuring secure integration with third-party services and enhancing application functionality.',
        icon: 'fas fa-plug',
      },
    ],
    languages: ['Italian', 'English'],
    hardSkills: [
      'Python',
      'ReactJS',
      'Relational Databases',
      'Time Series Databases',
      'MVC Frameworks',
      'RESTful API',
      'Testing',
      'CI/CD',
      'GIT',
      'Docker',
      'Linux',
    ],
    softSkills: [
      'Pragmatic',
      'Detail-oriented',
      'Persistent',
    ],
    workHistory: [
      {
        id: 9,
        jobTitle: 'Senior Fullstack Engineer',
        company: 'Growens S.p.A',
        location: 'Milan, Lombardia',
        achievements: ['Developing cutting-edge frontend features for the BeeFree app using ShapeUp product development methodology.'],
        technologies: [
          Technologies.React,
          Technologies.TypeScript,
          Technologies.Sass,
          Technologies.Django,
          Technologies.Python,
          Technologies.PostgreSQL,
          Technologies.Docker,
          Technologies.GitHub,
          Technologies.AWS,
          Technologies.Docker,
        ],
        startDate: '2024-07',
        endDate: 'current',
      },
      {
        id: 8,
        jobTitle: 'Senior Frontend Engineer',
        company: 'Growens S.p.A',
        location: 'Milan, Lombardia',
        achievements: ["Developed and maintained the frontend of the company's main product, a web application for managing the company's data. The application was built with React, Redux, and TypeScript. I also worked on the backend, which was built with Node.js and Express."],
        technologies: [
          Technologies.React,
          Technologies.TypeScript,
          Technologies.Sass,
          Technologies.GitHub,
        ],
        startDate: '2021-04',
        endDate: '2024-07',
      },
      {
        id: 7,
        jobTitle: 'Senior Frontend Engineer',
        company: 'The Connected Pet Company, LLC',
        location: 'Remote',
        achievements: [
          'Developed the comprehensive frontend pet insurance purchase section, ensuring a seamless user experience and effective communication of policy details and options.',
          'Actively collaborated with backend developers to devise an intuitive API set, fostering ease of use and efficient integration.',
        ],
        technologies: [
          Technologies.React,
          Technologies.TypeScript,
          Technologies.Python,
          Technologies.Sass,
          Technologies.GitHub,
          Technologies.Docker,
        ],
        startDate: '2023-10',
        endDate: '2023-12',
      },
      {
        id: 6,
        jobTitle: 'Senior Frontend Engineer',
        company: 'Fees',
        location: 'Remote',
        achievements: [
          'Spearheaded the migration of feesCAN codebase from JavaScript to Python.',
          'Dockerized all web applications and established comprehensive CI automation, leveraging GitHub Actions integrated with Portainer for streamlined building and deployment.',
          'Provided ongoing support for bug fixes and seamlessly implemented minor features.',
        ],
        technologies: [
          Technologies.GitHub,
          Technologies.Portainer,
          Technologies.AWS,
          Technologies.PHP,
          Technologies.Python,
          Technologies.Angular,
          Technologies.Ionic,
          Technologies.Docker,
        ],
        startDate: '2022-05',
        endDate: '2023-09',
      },
      {
        id: 5,
        jobTitle: 'Senior Full Stack Engineer',
        company: 'B2Z Insurance',
        location: 'Remote',
        achievements: [
          'Provided frontend support for the development of new features on the B2Z insurance platform.',
        ],
        technologies: [
          Technologies.React,
          Technologies.Sass,
          Technologies.TypeScript,
          Technologies.Express,
          Technologies.Docker,
          Technologies.GitHub,
        ],
        startDate: '2021-06',
        endDate: '2022-05',
      },
      {
        id: 4,
        jobTitle: 'Head of Product Development',
        company: 'Officine Facco S.p.A.',
        location: ' Campo San Martino, PD, Italy',
        achievements: ['Managed Smart Black Box and Portal maintenance lifecycle while leading a team of 3 external consultants to release new product features.'],
        technologies: [
          Technologies.Django,
          Technologies.React,
          Technologies.Redux,
          Technologies.PostgreSQL,
          Technologies.Python,
          Technologies.InfluxDB,
          Technologies.Redis,
          Technologies.Sass,
          Technologies.Docker,
          Technologies.GitLab,
        ],
        startDate: '2020-01',
        endDate: '2021-04',
      },
      {
        id: 3,
        jobTitle: 'Senior Full Stack Web Developer',
        company: 'FACCO USA',
        location: 'Chicago, Illinois, USA',
        achievements: [
          'Developed Smart Black Boxes, an IoT data logger single page web application.',
          'Developed Portal, a highly interactive single page web application used as a monitoring tool to collect metrics from Smart Black Box nodes and track performance of industrial machinery.',
          "Led the migration of all Facco USA data center's services to the headquarters in Italy.",
        ],
        technologies: [
          Technologies.Django,
          Technologies.React,
          Technologies.Redux,
          Technologies.PostgreSQL,
          Technologies.InfluxDB,
          Technologies.Python,
          Technologies.Redis,
          Technologies.Sass,
          Technologies.Docker,
          Technologies.GitLab,
        ],
        startDate: '2016-12',
        endDate: '2020-01',
      },
      {
        id: 2,
        jobTitle: 'Web Developer',
        company: 'Electric 80 Inc',
        location: 'Skokie, Illinois, USA',
        achievements: [
          "Developed Convergence, a ticketing system used to manage tickets and issues encountered by the company's customers.",
        ],
        technologies: [
          Technologies.Laravel,
          Technologies.PHP,
          Technologies.jQuery,
          Technologies.Sass,
          Technologies.mySQL,
          Technologies.GitHub,
        ],
        startDate: '2015-10',
        endDate: '2016-12',
      },
      {
        id: 1,
        jobTitle: 'PC Engineer',
        company: 'Electric 80 Inc',
        location: 'Skokie, Illinois, USA',
        achievements: [
          'Finalized on site installations of SDM (warehouse management system) integrated with LGV Manager (laser guided vehicle manager).',
          'Developed modifications and extensions for company proprietary software LGV Manager, Labeling System and Pallet Pattern Tool software.',
        ],
        technologies: [
          Technologies.CSharp,
          Technologies.MSSQL,
          Technologies.Linq,
          Technologies.Subversion,
        ],
        startDate: '2014-11',
        endDate: '2016-12',
      },
      {
        id: 0,
        jobTitle: 'Junior Web Developer',
        company: 'Intecom s.r.l.',
        location: "Reggio nell'Emilia, Italy",
        achievements: [
          'Wrote server-side and client-side code for custom PHP project using PHP, HTML, CSS and JavaScript.',
          'Created new Magento platform to launch Tecnocomputer e-commerce.',
        ],
        technologies: [
          Technologies.PHP,
          Technologies.jQuery,
          Technologies.mySQL,
          Technologies.GitHub,
        ],
        startDate: '2013-03',
        endDate: '2014-11',
      },
    ],
    education: [
      {
        degree: 'Bachelor of Science: Computer Engineering',
        school: 'Università Degli Studi Di Parma',
        location: 'Parma, Italy',
        startDate: '2011-09',
        endDate: '2013-07',
      },
      {
        degree: 'Associate of Science: Computer Engineering',
        school: 'San Diego City College',
        location: 'San Diego',
        startDate: '2009-09',
        endDate: '2011-09',
      },
      {
        degree: 'TOEFL: English',
        school: 'Kaplan Aspect',
        location: 'San Diego',
        startDate: '2009-09',
        endDate: '2011-09',
      },
    ],
    projects: [
      {
        name: 'The Dolly Experience',
        description: 'Specialized piece of lmmaking and television production equipment designed to create smooth camera movement',
      },
    ],
    hobbies: [
      {
        name: 'Music',
        description: 'Passionate piano and drums player.',
      },
      {
        name: 'Games',
        description: 'Likes to play chess.',
      },
    ],
  }

  const fullName = `${data.firstName} ${data.lastName}`
  const city = data.info.find((item) => item.key === 'city')?.value
  const country = data.info.find((item) => item.key === 'country')?.value
  const email = data.info.find((item) => item.key === 'e-mail')?.value
  const phone = data.info.find((item) => item.key === 'phone')?.value
  const birthdate = data.info.find((item) => item.key === 'birthdate')?.value
  const age = birthdate ? moment().diff(moment(birthdate, 'DD-MM-YYYY'), 'years') : 0

  const monthsOfExperience = (experiences?: number) => data.workHistory.slice(0, experiences).reduce((acc, job) => {
    const startDate = moment(job.startDate)
    const endDate = job.endDate === 'current' ? moment() : moment(job.endDate)
    return acc + endDate.diff(startDate, 'months')
  }, 0)

  const yearsOfExperience = (experiences?: number) => Math.floor(monthsOfExperience(experiences) / 12)
  const technologiesCount = data.workHistory.reduce((acc, job) => acc + job.technologies.length, 0)

  const getTechnologyMonths = (experiences?: number) => data.workHistory.slice(0, experiences).reduce<Record<Technologies, number>>((acc, job) => {
    const startDate = moment(job.startDate)
    const endDate = job.endDate === 'current' ? moment() : moment(job.endDate)
    const months = endDate.diff(startDate, 'months')
    return job.technologies.reduce<Record<Technologies, number>>((innerAcc, tech) => ({
      ...innerAcc,
      [tech]: innerAcc[tech] ? innerAcc[tech] + months : months,
    }), acc)
  }, {} as Record<Technologies, number>)

  const getSkills = (experiences?: number) => Object.values(Technologies).map((tech) => {
    const technologyMonths = getTechnologyMonths(experiences)
    return {
      name: tech,
      percent: ((technologyMonths[tech] / monthsOfExperience(experiences)) * 100),
    }
  }).filter((e) => !!e.percent).sort((a, b) => b.percent - a.percent)

  return {
    ...data,
    fullName,
    city,
    country,
    email,
    phone,
    birthdate,
    age,
    yearsOfExperience,
    technologiesCount,
    getSkills,
  }
}

export { useData }
