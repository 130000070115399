import { Link } from 'react-router-dom'
import React from 'react'
import { useConfig } from '../hooks/useConfig'
import { useData } from '../hooks/useData'

const AboutUs = () => {
  const { darkTheme, classicHeader } = useConfig()
  const {
    firstName,
    lastName,
    title,
    webIntro,
    email,
    age,
    city,
    country,
    yearsOfExperience,
    technologiesCount,
    workHistory,
  } = useData()

  return (
    <section id="about" className={`section ${darkTheme ? 'bg-dark-1' : ''}`}>
      <div className={`container ${classicHeader ? '' : 'px-lg-5'}`}>
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={`text-24  text-uppercase fw-600 w-100 mb-0 ${darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4'}`}>
            About Me
          </h2>
          <p className={`text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ${darkTheme ? 'text-white' : 'text-dark'}`}>
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        <div className="row gy-5">
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2 className={`text-7 fw-600 mb-3 ${darkTheme ? 'text-white' : ''}`}>
              Im
              {' '}
              <span className="text-primary">
                {`${firstName} ${lastName}, `}
              </span>
              {`a ${title}`}
            </h2>
            <p className={darkTheme ? 'text-white-50' : ''}>
              {webIntro.split('\n').map((line) => (
                <React.Fragment key={line}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  `list-style-2 ${
                    darkTheme ? 'list-style-light text-light' : ''}`
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>
                  {`${firstName} ${lastName}`}
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href={`mailto: ${email}`}>{email}</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>
                  {age}
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>
                  {`${city}, ${country}`}
                </li>
              </ul>
              <Link
                target="_blank"
                to="resume"
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </Link>
            </div>
          </div>
        </div>
        <div
          className={
            `brands-grid separator-border mt-5 ${
              darkTheme ? 'separator-border-light' : ''}`
          }
        >
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    `text-12  mb-0 ${
                      darkTheme ? 'text-white-50' : 'text-muted'}`
                  }
                >
                  <span>{ yearsOfExperience() }</span>
                  +
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    `text-12  mb-0 ${
                      darkTheme ? 'text-white-50' : 'text-muted'}`
                  }
                >
                  <span>{ technologiesCount }</span>
                  +
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Technologies Used
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    `text-12  mb-0 ${
                      darkTheme ? 'text-white-50' : 'text-muted'}`
                  }
                >
                  <span>{ workHistory.length }</span>
                  +
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Projects Done
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  )
}

export { AboutUs }
