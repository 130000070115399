import { createBrowserRouter } from 'react-router-dom'
import { Resume } from './pages/pdf'
import { WebResume } from './pages/WebResume'

const router = createBrowserRouter([
  {
    path: '/',
    element: <WebResume />,
  },
  {
    path: '/resume',
    element: <Resume />,
  },
])

export { router }
