import Slider from 'react-slick'
import { useConfig } from '../hooks/useConfig'
import { useData } from '../hooks/useData'

const Testimonials = () => {
  const { classicHeader, darkTheme } = useConfig()
  const { reviews } = useData()

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }
  return (
    <section
      id="testimonial"
      className={`section ${darkTheme ? 'bg-dark-1' : ''}`}
    >
      <div className={`container ${classicHeader ? '' : 'px-lg-5'}`}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              `text-24  text-uppercase fw-600 w-100 mb-0 ${
                darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4'}`
            }
          >
            Testimonial
          </h2>
          <p
            className={
              `text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ${
                darkTheme ? 'text-white' : 'text-dark'}`
            }
          >
            {' '}
            Client Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end */}

        <Slider
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...settings}
        >
          {reviews.length > 0
            && reviews.map((value) => (
              <div className="item" key={value.name}>
                <div
                  className={
                    ` rounded p-5 ${darkTheme ? 'bg-dark' : 'bg-light'}`
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          `d-block fw-600 ${
                            darkTheme ? 'text-white' : 'text-dark'}`
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {' '}
                        {value.position}
                        {' '}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      `fw-500 mb-4 ${darkTheme ? 'text-white' : 'text-dark'}`
                    }
                  >
                    “
                    {value.desc}
                    ”
                  </p>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  )
}

export { Testimonials }
