import { ReactNode } from 'react'
import * as rdd from 'react-device-detect'

type DeviceProps = {
  children: (props: typeof rdd) => ReactNode;
};

const Device = ({ children }: DeviceProps) => <div className="device-layout-component">{children(rdd)}</div>

export { Device }
