import { useState } from 'react'
import { Link } from 'react-scroll'
import { Tooltip } from './Tooltip'
import { useData } from '../hooks/useData'

const Header = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true)
  const { firstName, lastName, info } = useData()
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link
            smooth
            duration={500}
            style={{ cursor: 'pointer' }}
            to="home"
            className="mb-lg-auto mt-lg-4"
            onClick={() => {
              setIsNavModalClose(true)
            }}
          >
            <img
              className="img-fluid rounded-pill d-block p-2 d-none d-lg-inline-block"
              src="images/profile.jpg"
              title={`I'm ${firstName}`}
              alt="profile"
            />
            <h1 className="text-5 text-white text-center mb-0 d-lg-block">
              {`${firstName} ${lastName}`}
            </h1>
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? 'collapse navbar-collapse w-100 my-lg-auto '
                : 'show navbar-collapse w-100 my-lg-auto'
            }
          >
            <ul className="navbar-nav text-lg-center my-lg-auto py-lg-3">
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  About Me
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="services"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  What I Do
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="resume"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  Resume
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="portfolio"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  Portfolio
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="testimonial"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  Testimonial
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={() => {
                    setIsNavModalClose(true)
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <ul className="social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-flex">
            { info.filter((item) => item.tag === 'socials').map((item) => (
              <li key={item.key} className="social-icons-facebook">
                <Tooltip text={item.key} placement="top">
                  <a
                    href={item.value}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={`fab fa-${item.icon}`} />
                  </a>
                </Tooltip>
              </li>
            ))}
          </ul>
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose)
            }}
            className={
              isNavModalClose ? 'navbar-toggler' : 'navbar-toggler show'
            }
            id="navbar-toggler"
            type="button"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  )
}

export { Header }
