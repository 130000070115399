import { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { Home } from '../components/Home'
import { AboutUs } from '../components/About'
import { Services } from '../components/Services'
import { Resume } from '../components/Resume'
// import { Portfolio } from '../components/Portfolio'
import { Testimonials } from '../components/Testimonials'
import { Contact } from '../components/Contact'
import { Footer } from '../components/Footer'
import { ClassicHeader } from '../components/ClassicHeader'
import { TermsAndConditions } from '../components/TermsAndConditions'
import { Disclaimer } from '../components/Disclaimer'
import { PreLoader } from '../components/Preloader'
import { Tooltip } from '../components/Tooltip'
import { useConfig } from '../hooks/useConfig'

const WebResume = () => {
  const { classicHeader } = useConfig()

  const handleNavClick = (section: string) => {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' })
  }

  const [scrollTopVisible, setScrollTopVisible] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setisLoading(false)
    }, 1000)
    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [])

  const checkScrollTop = () => {
    const scrollTopBtn = document.getElementById('back-to-top')

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400
        || document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true)
      } else {
        setScrollTopVisible(false)
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', checkScrollTop)
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={classicHeader ? '' : 'side-header'}
    >
      {isLoading && <PreLoader />}

      <div id="main-wrapper">
        {classicHeader ? <ClassicHeader /> : <Header />}
        <div id="content" role="main">
          <Home handleNavClick={handleNavClick} />
          <AboutUs />
          <Services />
          <Resume />
          {/* <Portfolio /> */}
          <Testimonials />
          <Contact />
        </div>
        <Footer handleNavClick={handleNavClick} />
      </div>
      {/* back to top */}
      <Tooltip text="Back to Top" placement="left">
        <span
          id="back-to-top"
          className="rounded-circle"
          style={{ display: scrollTopVisible ? 'inline' : 'none' }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          <i className="fa fa-chevron-up" />
        </span>
      </Tooltip>

      <TermsAndConditions />
      <Disclaimer />
    </div>
  )
}

export { WebResume }
