import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
    margin: '0 5px',
  },
})

interface ListItemProps {
  children: React.ReactNode;
}

const ListItem = ({ children }: ListItemProps) => (
  <View style={styles.row}>
    <View style={styles.bullet}>
      <Text>{'\u2022'}</Text>
    </View>
    <Text>{children}</Text>
  </View>
)

export { ListItem }
