import React from 'react'
import { Document, View, Text, Page, Font, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import { ListItem } from './components/ListItems'
import { useData } from '../../hooks/useData'
import { Device } from './components/Device'

Font.register({
  family: 'Century Gothic',
  src: '/GOTHIC.TTF',
  fontStyle: 'normal',
  fontWeight: 'normal',
})

Font.register({
  family: 'Century Gothic',
  src: '/GOTHICB.TTF',
  fontStyle: 'normal',
  fontWeight: 'bold',
})

Font.register({
  family: 'Century Gothic',
  src: '/GOTHICI.TTF',
  fontStyle: 'italic',
  fontWeight: 'normal',
})

// this avoids words to be broken down in the middle
Font.registerHyphenationCallback((word: string) => [word])

const styles = StyleSheet.create({
  firstName: {
    fontWeight: 'bold',
    fontSize: 25,
  },
  lastName: {
    fontWeight: 'bold',
    fontSize: 25,
    marginBottom: 10,
  },
  title: {
    fontSize: 10,
  },
  bodyParagrahContainer: {
    flexDirection: 'row',
    marginTop: 15,
  },
  dateContainer: {
    width: '24%',
  },
  detailsContainer: {
    width: '76%',
  },
  detailsContainerTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 12,
    lineHeight: 1,
  },
  detailsContainerSubtitle: {
    fontStyle: 'italic',
  },
  bodyHeadingText: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '5px 0px',
    lineHeight: 1,
    color: '#013d73',
    borderTop: '1.5px solid #e0e1e1',
    borderBottom: '1.5px solid #e0e1e1',
    marginTop: 15,
  },
  page: {
    fontFamily: 'Century Gothic',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  sidebar: {
    fontSize: 10,
    width: '30%',
    backgroundColor: '#013d73',
    color: '#fff',
  },
  mainContent: {
    width: '70%',
    backgroundColor: '#fff',
    padding: '16px 40px 16px 16px',
    fontSize: 9,
    lineHeight: 1.7,
    whiteSpace: 'pre-wrap',
    color: '#484848',
  },
  sidebarSectionHeader: {
    fontWeight: 'bold',
    backgroundColor: '#01315c',
    padding: '5px 15px',
    fontSize: 12,
  },
  sidebarSectionBody: {
    padding: '10px 15px',
  },
  sidebarBlockTitle: {
    marginBottom: 5,
    fontWeight: 'bold',
  },
  sidebarBlockValue: {
    marginBottom: 10,
  },
  solutionStack: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
    marginTop: 5,
  },
  solutionStackElement: {
    backgroundColor: '#f0f0f0',
    padding: '2px 5px',
    borderRadius: 5,
    lineHeight: 1.3,
  },
})

interface SidebarBlockProps {
  title?: string;
  value: string;
}

const SidebarBlock = ({ title, value }: SidebarBlockProps) => (
  <View>
    { title ? (
      <Text style={styles.sidebarBlockTitle}>
        {title}
      </Text>
    ) : null }
    <Text style={styles.sidebarBlockValue}>{value}</Text>
  </View>
)

interface SidebarSectionProps {
  name?: string,
  children: React.ReactElement<SidebarBlockProps> | React.ReactElement<SidebarBlockProps>[];
}

const SidebarSection = ({ name, children }: SidebarSectionProps) => (
  <View wrap={false}>
    { name
      ? <Text style={styles.sidebarSectionHeader}>{name}</Text>
      : null}
    <View style={styles.sidebarSectionBody}>
      {children}
    </View>
  </View>
)

interface BodyBlockProps {
  fromDate?: string;
  toDate?: string;
  title?: string;
  subTitle?: string;
  achievements?: string[];
  stack?: string[];
  compressed?: boolean;
}

const BodyBlock = ({
  fromDate,
  toDate,
  title,
  subTitle,
  achievements = [],
  compressed = false,
  stack = [],
}: BodyBlockProps) => {
  const achievementsList = achievements.length > 1
    ? achievements.map((achievement) => <ListItem key={achievement}>{achievement}</ListItem>)
    : <Text>{achievements[0]}</Text>

  const stackList = (
    <View style={styles.solutionStack}>
      { stack.map((e) => <Text key={e} style={styles.solutionStackElement}>{e}</Text>) }
    </View>
  )

  return (
    <View
      style={[styles.bodyParagrahContainer]}
      wrap={false}
    >
      <View style={styles.dateContainer}>
        { fromDate ? <Text>{`${fromDate} -`}</Text> : null }
        { toDate ? <Text>{toDate}</Text> : null }
      </View>
      <View style={styles.detailsContainer}>
        <Text style={[
          styles.detailsContainerTitle,
          compressed ? { fontSize: 10 } : {},
        ]}
        >
          {title}
        </Text>
        <Text style={styles.detailsContainerSubtitle}>{subTitle}</Text>
        { achievements.length > 0 ? achievementsList : null }
        { stack.length > 0 ? stackList : null }
      </View>
    </View>
  )
}

interface BodySectionProps {
  name: string,
  children: React.ReactElement<BodyBlockProps> | React.ReactElement<BodyBlockProps>[];
}

const BodySection = ({ name, children }: BodySectionProps) => (
  <View>
    <Text style={styles.bodyHeadingText}>{name}</Text>
    {children}
  </View>
)

const MyDocument = () => {
  const data = useData()
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.sidebar}>
          <SidebarSection>
            <Text style={styles.firstName}>{data.firstName}</Text>
            <Text style={styles.lastName}>{data.lastName}</Text>
            <SidebarBlock value={data.title} />
          </SidebarSection>
          <SidebarSection name="Contacts">
            { data.info.filter((e) => e.tag === 'contacts').map((contact) => (
              <SidebarBlock key={contact.key} title={contact.key} value={contact.value} />
            ))}
          </SidebarSection>
          <SidebarSection name="Hard Skills">
            {
          data.hardSkills.map((hardSkill) => (
            <SidebarBlock value={hardSkill} key={hardSkill} />
          ))
        }
          </SidebarSection>
          <SidebarSection name="Soft Skills">
            {
          data.softSkills.map((softSkill) => (
            <SidebarBlock value={softSkill} key={softSkill} />
          ))
        }
          </SidebarSection>
          <SidebarSection name="Languages">
            { data.languages.map((language) => <SidebarBlock value={language} key={language} />)}
          </SidebarSection>
        </View>
        <View style={styles.mainContent}>
          <Text>{data.intro}</Text>
          <BodySection name="Profiles">
            {data.info.filter((e) => e.tag === 'socials').map((social) => (
              <BodyBlock
                key={social.key}
                title={social.key}
                subTitle={social.value}
                compressed
              />
            ))}
          </BodySection>
          <BodySection name="Work History">
            {data.workHistory.map((work) => (
              <BodyBlock
                key={work.startDate + work.endDate}
                fromDate={work.startDate}
                toDate={work.endDate}
                title={work.jobTitle}
                subTitle={`${work.company} ${work.location}`}
                achievements={work.achievements}
                stack={work.technologies}
              />
            ))}
          </BodySection>
          <BodySection name="Education">
            {data.education.map((study) => (
              <BodyBlock
                key={study.startDate + study.endDate}
                fromDate={study.startDate}
                toDate={study.endDate}
                title={study.degree}
                subTitle={`${study.school} ${study.location}`}
              />
            ))}
          </BodySection>
          <BodySection name="Personal Projects">
            {data.projects.map((project) => (
              <BodyBlock
                key={project.name}
                title={project.name}
                achievements={[project.description]}
                compressed
              />
            ))}
          </BodySection>
          <BodySection name="Hobbies">
            <BodyBlock
              achievements={data.hobbies.map((hobby) => hobby.description)}
              compressed
            />
          </BodySection>
        </View>
      </Page>
    </Document>
  )
}

const Resume = () => (
  <Device>
    {({ isMobile }) => {
      if (isMobile) {
        return (
          <PDFDownloadLink
            document={<MyDocument />}
            fileName="francesco-meli-resume.pdf"
          >
            {({ loading }) => (loading ? 'Loading...' : 'Download PDF')}
          </PDFDownloadLink>
        )
      }
      return (
        <PDFViewer style={{ width: '100%', height: '100vh', border: 'none' }}>
          <MyDocument />
        </PDFViewer>
      )
    }}
  </Device>
)

export { Resume }
