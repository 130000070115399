import { Link } from 'react-router-dom'
import { useConfig } from '../hooks/useConfig'
import { useData } from '../hooks/useData'

const Resume = () => {
  const { classicHeader, darkTheme } = useConfig()
  const { getSkills, workHistory, education } = useData()
  const skills = getSkills().slice(0, 10).map((skill) => ({
    name: skill.name,
    percent: Math.ceil(skill.percent / 5) * 5,
  }))

  return (
    <section
      id="resume"
      className={`section ${darkTheme ? 'bg-dark-1' : ''}`}
    >
      <div className={`container ${classicHeader ? '' : 'px-lg-5'}`}>
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={`text-24  text-uppercase fw-600 w-100 mb-0 ${darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4'}`}>
            Summary
          </h2>
          <p className={`text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ${darkTheme ? 'text-white' : 'text-dark'}`}>
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        <div className="row gx-5">
          <div className="col-md-6">
            <h2 className={`text-6 fw-600 mb-4 ${darkTheme ? 'text-white' : ''}`}>
              My Experience
            </h2>
            {workHistory.length > 0 && workHistory.map((value) => (
              <div
                key={value.id}
                className={`bg-white rounded p-4 mb-4 ${darkTheme ? 'bg-dark' : 'bg-white border'}`}
              >
                <p className="badge bg-primary text-2 fw-400">
                  {`${value.startDate} ${value.endDate}`}
                </p>
                <h3 className={`text-5 ${darkTheme ? 'text-white' : ''}`}>
                  {value.jobTitle}
                </h3>
                <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                  {value.company}
                </p>
                <p className={`mb-0 ${darkTheme ? 'text-white-50' : ''}`}>
                  {value.achievements.map((achievement) => (
                    <div key={achievement} className="d-block">
                      {achievement}
                    </div>
                  ))}
                </p>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <h2 className={`text-6 fw-600 mb-4 ${darkTheme ? 'text-white' : ''}`}>
              My Education
            </h2>
            { education.length > 0 && education.map((value) => (
              <div
                key={value.degree}
                className={
                    `bg-white  rounded p-4 mb-4 ${
                      darkTheme ? 'bg-dark' : 'bg-white border'}`
                  }
              >
                <p className="badge bg-primary text-2 fw-400">
                  {`${value.startDate} ${value.endDate}`}
                </p>
                <h3 className={`text-5 ${darkTheme ? 'text-white' : ''}`}>
                  {value.degree}
                </h3>
                <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                  {value.school}
                </p>
                <p className={`mb-0 ${darkTheme ? 'text-white-50' : ''}`}>
                  {value.location}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            `text-6 fw-600 mt-4 mb-4 ${darkTheme ? 'text-white' : ''}`
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0
            && skills.map((skill) => (
              <div key={skill.name} className="col-md-6">
                <p
                  className={
                    ` fw-500 text-start mb-2 ${
                      darkTheme ? 'text-light' : 'text-dark'}`
                  }
                >
                  {skill.name}
                  {' '}
                  <span className="float-end">
                    {skill.percent.toFixed(0)}
                    %
                  </span>
                </p>
                <div
                  className={
                    `progress progress-sm mb-4 ${darkTheme ? 'bg-dark' : ''}`
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${skill.percent}%` }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <Link
            className="btn btn-outline-secondary rounded-pill shadow-none"
            to="/resume"
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export { Resume }
