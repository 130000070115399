const PreLoader = () => (
  <div className="preloader">
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export { PreLoader }
