import { useConfig } from '../hooks/useConfig'
import { useData } from '../hooks/useData'

const TermsAndConditions = () => {
  const { darkTheme } = useConfig()
  const { firstName } = useData()
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            `modal-content ${darkTheme ? 'bg-dark-2 text-light' : ''}`
          }
        >
          <div className="modal-header">
            <h5 className={`modal-title ${darkTheme ? 'text-white' : ''}`}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={`btn-close ${darkTheme ? 'btn-close-white' : ''}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              Welcome to my personal website.
              Please read the following terms and conditions carefully.
            </p>
            <h3 className={`mb-3 mt-4 ${darkTheme ? 'text-white' : ''}`}>
              Terms of Use
            </h3>
            <p>
              By accessing and using this website, you agree to comply with and
              be bound by the following terms and conditions. Please review these terms carefully.
            </p>
            <h5 className={`text-4 mt-4 ${darkTheme ? 'text-white' : ''}`}>
              Part I – Information
              {' '}
              {firstName}
              {' '}
              collects and controls
            </h5>
            <p>
              This website collects basic information such as your IP address
              and browser type for analytics purposes. If you choose to contact
              me through the website, any personal information you provide will
              be used solely for the purpose of responding to your inquiries.
            </p>
            <h5 className={`text-4 mt-4 ${darkTheme ? 'text-white' : ''}`}>
              Part II – Information that
              {' '}
              {firstName}
              {' '}
              processes on your behalf
            </h5>
            <p>
              Any personal data you provide through contact forms or email
              communications will be processed solely to address your queries
              or feedback. This data will not be shared with third parties
              without your explicit consent.
            </p>
            <h5 className={`text-4 mt-4 ${darkTheme ? 'text-white' : ''}`}>
              Part III – General
            </h5>
            <p>
              These terms constitute the entire agreement between you and me
              regarding the use of this website. If any provision of these terms
              is found to be invalid or unenforceable, the remaining provisions
              will remain in full force and effect.
            </p>
            <h3 className={`mb-3 mt-4 ${darkTheme ? 'text-white' : ''}`}>
              Privacy Policy
            </h3>
            <p>
              Your privacy is important to me. This policy outlines the types
              of personal information I receive and collect when you use this
              website, as well as some of the steps I take to safeguard information.
            </p>
            <ol className="lh-lg">
              <li>
                I collect information to provide better services to all my users,
                including basic information about my website traffic and
                customizing the user experience.
              </li>
              <li>
                I do not share your personal information with companies,
                organizations, or individuals outside of my personal control,
                except as required by law or with your consent.
              </li>
              <li>
                I implement security measures designed to protect your
                information from unauthorized access, loss, misuse, or alteration.
              </li>
              <li>
                You have the right to access and update your personal
                information at any time by contacting me directly.
              </li>
              <li>
                I may update these terms and privacy policy from time to time.
                I will notify you of any changes by posting the new terms on this website.
              </li>
              <li>
                By continuing to use this website after those changes become
                effective, you agree to be bound by the revised terms.
              </li>
              <li>
                If you have any questions or concerns about these terms or the
                privacy policy, please feel free to contact me.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TermsAndConditions }
